<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="车系" prop="carTrain">
          <el-input v-model="formInline.carTrain" placeholder="请输入车系"></el-input>
        </el-form-item>
        <el-form-item label="车型编码" prop="code">
          <el-input v-model="formInline.code" placeholder="请输入车型编号"></el-input>
        </el-form-item>
        <el-form-item label="中文名称" prop="nameCh">
          <el-input v-model="formInline.nameCh" placeholder="请输入中文名称"></el-input>
        </el-form-item>
        <el-form-item label="车型年款" prop="year">
          <el-input v-model="formInline.year" placeholder="请输入车型年款"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">搜索</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus" @click="handeladd()">新增</el-button>
      <el-upload
                class="upload-demo inline-block margin-left-10 margin-right-10"
                ref="melUpload"
                action="#"
                :show-file-list="false"
                 multiple
                :limit="1"
                :file-list="fileList"
                :before-upload="onBeforeUpload"
                :http-request="uploadModel"
              >
        <el-button size="min" icon="el-icon-upload" type="primary">批量导入车型</el-button>
      </el-upload>
      <el-button plain type="primary" icon="el-icon-coin" @click="downModel()">下载车型模板</el-button>
      <el-upload
                class="upload-demo inline-block margin-left-10 margin-right-10"
                ref="celUpload"
                action="#"
                :show-file-list="false"
                 multiple
                :limit="1"
                :file-list="fileList"
                :before-upload="onBeforeUpload"
                :http-request="uploadConfig"
              >
        <el-button size="min" icon="el-icon-upload" type="primary">批量导入配置</el-button>
      </el-upload>
      <el-button plain type="primary" icon="el-icon-coin" @click="downConfig()">下载配置模板</el-button>
      <el-table style="width:100%;" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="车系" prop="trainName" width="210px"></el-table-column>
        <el-table-column label="车型年款" prop="year" width="207px"></el-table-column>
        <el-table-column label="车型编码" prop="code" width="220px"></el-table-column>
        <el-table-column label="中文名称" prop="nameCh" width="220px"></el-table-column>
        <el-table-column label="英文名称" prop="nameEn" width="200px" ></el-table-column>
        <el-table-column label="图片" prop="image" width="130">
          <template slot-scope="{row}">
            <span v-if="row.image == null || row.image === ''">无</span>
            <el-button v-if="row.image !== null && row.image !== ''" type="primary" plain size="min" @click="check(row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="排序" prop="sort" width="130"></el-table-column>
        <el-table-column label="操作" fixed="right" width="330">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="handelDetile(row)">详情</el-button>
            <el-button type="primary" size="mini" @click="handeledit(row)">编辑</el-button>
            <el-button type="primary" size="mini" @click="config(row)">车型配置</el-button>
            <el-button type="primary" size="mini" style="background:#ff5722" @click="handeldelete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
        <!-- 查看 -->
        <div v-if="dialogStatus === 'check'" style="text-align: center; " >
          <img :src="urlImg" alt="" style="width:90%;height:70%">
        </div>
        <!-- 详情 -->
        <el-form v-if="dialogStatus === 'detail'" :rules="rules" ref='dataForm' :model="dataForm" label-position="center">
          <el-form-item label="车系" prop="trainId" :label-width="formLabelWidth">
            <el-input v-model="dataForm.trainName" readonly></el-input>
          </el-form-item>
          <el-form-item label="车型年款" prop="year" :label-width="formLabelWidth">
            <el-input v-model="dataForm.year" readonly></el-input>
          </el-form-item>
          <el-form-item label="车型编码" prop="code" :label-width="formLabelWidth">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
          <el-form-item label="中文名称" prop="nameCh" :label-width="formLabelWidth">
            <el-input v-model="dataForm.nameCh" readonly></el-input>
          </el-form-item>
          <el-form-item label="英文名称" prop="nameEn" :label-width="formLabelWidth">
            <el-input v-model="dataForm.nameEn" readonly></el-input>
          </el-form-item>
          <el-form-item label="车型别名" prop="alias" :label-width="formLabelWidth">
            <el-input v-model="dataForm.alias" readonly></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
            <el-input v-model="dataForm.sort" readonly></el-input>
          </el-form-item>
          <el-form-item label="图片" prop="image" :label-width="formLabelWidth">
            <div v-if="dataForm.image !==null && dataForm.image !=='' && dataForm.image !== 'null' && imgList !=null && imgList.length>0 ">
              <div v-for="(item, index) in imgList " :key="index">
                <img :src="item.url" class="imgShow" >
              </div>
            </div>
            <div v-if="dataForm.image == null || dataForm.image == '' || dataForm.image == 'null' || imgList == null ||  imgList.length== 0 " >
              无
            </div>
          </el-form-item>
        </el-form>
        <!-- 编辑 -->
        <el-form v-if="dialogStatus === 'edit'" :rules="rules"  ref='dataForm' :model="dataForm" label-position="center">
          <el-form-item label="车系" prop="trainId" :label-width="formLabelWidth">
            <select-tree ref="editSelectTree" :options="trainList" v-model="dataForm.trainId" :props="defaultProps"  @slectNode="slectTreeNode"  placeholder="请选择车系"/>
          </el-form-item>
          <el-form-item label="车型年款" prop="year" :label-width="formLabelWidth">
            <el-input v-model="dataForm.year"  type="number" :min="1" :max="9999"  @input="e => dataForm.year=parserNumber(e,1,9999)" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"   show-word-limit maxlength="10" style="padding:0 48px 0 0"  ></el-input>
          </el-form-item>
          <el-form-item label="车型编码" prop="code" :label-width="formLabelWidth">
            <el-input v-model="dataForm.code"  @input="e => dataForm.code= validForbid(e)" show-word-limit maxlength="20" style="padding:0 48px 0 0" ></el-input>
          </el-form-item>
          <el-form-item label="中文名称" prop="nameCh" :label-width="formLabelWidth">
            <el-input   v-model="dataForm.nameCh" @input="e => dataForm.nameCh= validForbid(e)" show-word-limit maxlength="20" style="padding:0 48px 0 0"  ></el-input>
          </el-form-item>
          <el-form-item label="英文名称" prop="nameEn" :label-width="formLabelWidth">
            <el-input show-word-limit maxlength="50" style="padding:0 48px 0 0"   v-model="dataForm.nameEn"></el-input>
          </el-form-item>
          <el-form-item label="车型别名" prop="alias" :label-width="formLabelWidth">
            <el-input  show-word-limit maxlength="20" style="padding:0 48px 0 0"  v-model="dataForm.alias"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
            <el-input type="number"  :min="1" :max="9999"  @input="e => dataForm.sort=parserNumber(e,1,9999)" v-model="dataForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="图片" prop="image" :label-width="formLabelWidth">
            <el-upload
              class="upload-demo"
              style="max-width: 379px;"
              :action="uploadUrl"
              :headers="importHeader"
              :on-preview="handlePreview"
              :on-success="handlesuccess"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :before-upload="beforeAvatarUpload"
              :on-exceed="handleExceed"
              multiple
              :limit="1"
              :file-list="imgList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              list-type="picture"
            >
              <el-button size="min" icon="el-icon-upload" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="editClick('dataForm')">
              立即提交
            </el-button>
            <el-button @click="dialogFormVisible = false">
              取消
            </el-button>
          </el-form-item>
        </el-form>
        <!-- 新增 -->
        <el-form v-if="dialogStatus === 'add'" :rules="rules" ref='dataForm' :model="dataForm" label-position="center">
          <el-form-item label="车系" prop="trainId" :label-width="formLabelWidth">
            <select-tree ref="addSelectTree" :options="trainList" v-model="dataForm.trainId" :props="defaultProps"  @slectNode="slectTreeNode"  placeholder="请选择车系"  />
          </el-form-item>
          <el-form-item label="车型年款" prop="year" :label-width="formLabelWidth">
            <el-input v-model="dataForm.year"  type="number" :min="1" :max="9999" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" @input="e => dataForm.year=parserNumber(e,1,9999)"   placeholder="请输入车型年款"></el-input>
          </el-form-item>
          <el-form-item label="车型编码" prop="code" :label-width="formLabelWidth">
            <el-input v-model="dataForm.code" @input="e => dataForm.code= validForbid(e)" show-word-limit maxlength="20" style="padding:0 48px 0 0"  placeholder="请输入车型编码"></el-input>
          </el-form-item>
          <el-form-item label="中文名称" prop="nameCh" :label-width="formLabelWidth">
            <el-input v-model="dataForm.nameCh"  @input="e => dataForm.nameCh= validForbid(e)" show-word-limit maxlength="20" style="padding:0 48px 0 0"   placeholder="请输入中文名称"></el-input>
          </el-form-item>
          <el-form-item label="英文名称" prop="nameEn" :label-width="formLabelWidth">
            <el-input v-model="dataForm.nameEn" show-word-limit maxlength="50" placeholder="请输入英文名称" style="padding:0 48px 0 0"  ></el-input>
          </el-form-item>
          <el-form-item label="车型别名" prop="alias" :label-width="formLabelWidth">
            <el-input v-model="dataForm.alias" show-word-limit maxlength="20"  placeholder="请输入车型别名" style="padding:0 48px 0 0"  ></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
            <el-input type="number" :min="1" :max="9999"  @input="e => dataForm.sort=parserNumber(e,1,9999)"  v-model="dataForm.sort" placeholder="请输入排序"></el-input>
          </el-form-item>
          <el-form-item label="图片" prop="image" :label-width="formLabelWidth">
              <el-upload
                class="upload-demo"
                style="max-width: 379px;"
                :action="uploadUrl"
                :headers="importHeader"
                :on-preview="handlePreview"
                :on-success="handlesuccess"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :before-upload="beforeAvatarUpload"
                :on-exceed="handleExceed"
                multiple
                :limit="1"
                :file-list="imgList"
                accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
                list-type="picture"
              >
                <el-button size="min" icon="el-icon-upload" type="primary">点击上传</el-button>
              </el-upload>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="addClick('dataForm')">
              立即提交
            </el-button>
            <el-button @click="dialogFormVisible = false">
              取消
            </el-button>
          </el-form-item>
        </el-form>
        <!-- 车型配置 -->
        <el-form v-if="dialogStatus === 'config'">
          <el-button type="primary" icon="el-icon-plus" @click="addConfig">新增</el-button>
          <el-button type="primary"  icon="el-icon-delete" @click="delAllConfig" style="background:#ff5722;">删除全部配置</el-button>
          <el-table style="width:800px" border :data="modelCfgList">
            <el-table-column label="配置代码" wdith="300">
              <template slot-scope="{row}">
                <el-input v-model="row.code" oninput="value=value.replace(/^\s+|\s+$/g,'')" show-word-limit maxlength="50" style="padding:0 48px 0 0" placeholder="请输入车型代码"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="手册编码" width="150">
              <template slot-scope="{row}">
                <el-input v-model="row.alias" oninput="value=value.replace(/^\s+|\s+$/g,'')"  show-word-limit maxlength="50" style="padding:0 48px 0 0" :value="row.alias" placeholder="请输入手册编码"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="排序" width="80">
              <template slot-scope="{row}">
                <el-input type="number"  :min="1" :max="9999"  @input="e => row.sort=parserNumber(e,1,9999)"  v-model="row.sort" :value="row.sort" placeholder="请输入排序号"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="140">
              <template slot-scope="{row}">
                <el-button type="primary" @click="handelSaveConfig(row)">保存</el-button>
                <el-button type="primary" style="background:#ff5722;" @click="handelDelConfig(row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, obj } from '@/assets/js/common.js'
import $ from 'jquery'
import Pagination from '@/components/Pagination'
import SelectTree from '@/components/TreeView/SelectTree.vue';
import { modelData, listTrain, modelSearch, modelAdd, modelEdit, modelDel, cfgData, delAllCfg, delConfig, saveConfig,downModel,downConfig } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination ,SelectTree },
  data () {
    return {
      formInline: {
        carTrain: '',
        code: '',
        nameCh: '',
        year: ''
      },
      dataForm: {
        id: '',
        trainId: '',
        trainName: '',
        file: '',
        year: '',
        code: '',
        nameCh: '',
        nameEn: '',
        alias: '',
        modelType: '',
        marketTime: '',
        createdTime: '',
        createdUser: '',
        sort: 1,
        image: ''
      },
      // 默认选中值
      sltTrainId: '',
      trainList: [],
      // 数据默认字段
      defaultProps: {
        parent: 'pid',   // 父级唯一标识
        value: 'id',          // 唯一标识
        label: 'nameCh',       // 标签显示
        children: 'children', // 子级
      },
      uploadUrl: '',
      imgList: [],
      fileList: [],
      urlImg: '',
      dialogFormVisible: false,
      formLabelWidth: '100px',
      dialogStatus: '',
      textMap: {
        edit: '编辑车型',
        add: '新增车型',
        detail: '详情信息',
        config: '车型配置',
        check: '车型图片'
      },
      resultList: [],
      pagesize: 10,
      currentPage: 1,
      total: 0,
      modelId: '',
      modelCfgList: [],
      rules: {
        trainId: [{ required: true, message: '车系不能为空', trigger: 'blur' }],
        year: [{ required: true, message: '车型年款不能为空', trigger: 'blur' }],
        code: [{ required: true, message: '车型编码不能为空', trigger: 'blur' }],
        nameCh: [{ required: true, message: '中文名称不能为空', trigger: 'blur' }]
      },
    }
  },
  computed: {
    importHeader: function () {
      return { Authorization: sessionStorage.token }
    }
  },
  methods: {
    getTrainList () {
      listTrain().then(res => {
        this.trainList = res.data.data
      })
    },
    // 数据
    dataList () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        trainName: this.formInline.carTrain,
        modelCodePart: this.formInline.code,
        nameCh: this.formInline.nameCh,
        year: this.formInline.year
      }
      modelData(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    slectTreeNode(v){
      this.sltTrainId=this.dataForm.trainId
      this.$refs['dataForm'].validateField('trainId')
    },
    handlesuccess (file, fileList) {
      this.dataForm.image = file.data.fileUrl
      this.imgList = []
      if(this.dataForm.image != null && this.dataForm.image.length > 0){
        var img = {url: sysServerUrl + 'sys/upload/display?filePath=' + this.dataForm.image }
        this.imgList.push(img)
      }
    },
    beforeAvatarUpload (file) {
      const extension = file.name.split('.')[1] === 'png'
      const extension2 = file.name.split('.')[1] === 'jpg'
      const extension3 = file.name.split('.')[1] === 'jpeg'
      const extension4 = file.name.split('.')[1] === 'gif'
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!extension && !extension2 && !extension3 && !extension4) {
        this.$message.warning('上传模板只能是 png、jpg、jpeg、gif格式!')
      }
      if (!isLt2M) {
        this.$message.warning('上传模板大小不能超过 5MB!')
      }
      // return isLt2M
    },
    handleRemove (file, fileList) {
      if(fileList.length == "0"){
        this.imgList=[]
        this.dataForm.image = ''
      }
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除当前图片？`)
    },
    // 搜索
    onSubmit () {
       this.currentPage=1
          var params = {
            page: this.currentPage,
            limit: this.pagesize,
            trainName: this.formInline.carTrain,
            modelCodePart: this.formInline.code,
            nameCh: this.formInline.nameCh,
            year: this.formInline.year
          }
          modelSearch(params).then(res => {
            this.total = res.data.total
            this.resultList = res.data.data
          })

    },
    resetTemp () {
      this.dataForm = {
        id: '',
        trainId: '',
        trainName: '',
        file: '',
        year: '',
        code: '',
        nameCh: '',
        nameEn: '',
        alias: '',
        modelType: '',
        marketTime: '',
        createdTime: '',
        createdUser: '',
        sort: 1,
        image: ''
      }
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      if(this.dialogStatus == 'add'  || this.dialogStatus == 'edit' || this.dialogStatus == 'edit'){ 
        this.$refs.dataForm.resetFields()
      }
      this.textMap.config='车型配置'
      this.urlImg=''
     
    },
        // 附件上传
    onBeforeUpload(file) {
        var _this =this
      var fileExt =file.name.substring(file.name.lastIndexOf('.')+1).toLowerCase();  
      var text=""               
      const docExt = fileExt === 'xls' 
      const docxExt = fileExt === 'xlsx' 
      const isLimit = file.size / 1024 / 1024 < 2  
      if(!docExt && !docxExt) { 
        text="上传的文件只能是 xls、xlsx格式!";
          _this.$message.error(text)
        return false;
      }  
      if (!isLimit) {  
        text="上传的文件大小不能超过 2MB!";
         _this.$message.error(text)
        return false;
      }  
      return true;
    },

      //   this.$alert('确认删除吗', '提示', {

      //   confirmButtonText: '确定',
      //   callback: action => {
      //       this.function()
      //   }
      // });

    // 批量上传车型
    uploadModel (param) {
        var _this =this
			  var formData = new FormData(); 
			  formData.append('file', param.file);
        axios({
          method: 'post',
          url: sysServerUrl + 'sys/car/model/batchImport',
          data: formData
        }).then(res => {
          if (res.data.code === 100) {
          
           	 this.$message({
            type: 'success',
            message: '批量导入成功'
          })
          this.dataList()
           
          }else{
            _this.$alert(res.data.msg,'信息提示',{dangerouslyUseHTMLString:true})
          }
          _this.fileList = []
        }).catch(function(error){
           console.log(error)
           console.log(error.message)
           _this.fileList = []
           _this.$alert('系统出现异常，导入失败','信息提示',{dangerouslyUseHTMLString:true})
           
        })
			 
		},
    // 批量上传车型配置
    uploadConfig (param) {
       var _this =this
			  var formData = new FormData(); 
			  formData.append('file', param.file);
        axios({
          method: 'post',
          url: sysServerUrl + 'sys/car/model/batchImportCarCfg',
          data: formData
        }).then(res => {
          if (res.data.code === 100) {
          
           	 this.$message({
            type: 'success',
            message: '批量导入成功'
          })
          this.dataList()

          }else{
           _this.$alert(res.data.msg,'信息提示',{dangerouslyUseHTMLString:true})
          }
            _this.fileList = []
        }).catch(function(error){
           console.log(error)
           console.log(error.message)
            _this.fileList = []
           _this.$alert('系统出现异常，导入失败','信息提示',{dangerouslyUseHTMLString:true})

        })
			 
		},
    // 下载车型模板
    downModel () {
      var params =''; 
      downModel(params).then(res => {
                  console.log(res)
                  if(!res.data){
                       return
                   }
                  var name = "车型导入模板.xlsx";
                  var blob = new Blob([res.data]);
                  var url = window.URL.createObjectURL(blob);
                  var aLink = document.createElement("a");
                  aLink.style.display = "none";
                  aLink.href = url;
                  aLink.setAttribute("download", name);
                  document.body.appendChild(aLink);
                  aLink.click();
                  document.body.removeChild(aLink); //下载完成移除元素
                  window.URL.revokeObjectURL(url); //释放掉blob对象
                })

    },
    // 下载配置模板
    downConfig () {
      var params =''; 
      downConfig(params).then(res => {
                  console.log(res)
                  if(!res.data){
                       return
                   }
                  var name = "车型配置导入模板.xlsx";
                  var blob = new Blob([res.data]);
                  var url = window.URL.createObjectURL(blob);
                  var aLink = document.createElement("a");
                  aLink.style.display = "none";
                  aLink.href = url;
                  aLink.setAttribute("download", name);
                  document.body.appendChild(aLink);
                  aLink.click();
                  document.body.removeChild(aLink); //下载完成移除元素
                  window.URL.revokeObjectURL(url); //释放掉blob对象
                })

    },
    // 新增
    handeladd () {
      var _this=this
      this.resetTemp()
      this.imgList=[]
      this.dialogStatus = 'add'
      this.dialogFormVisible = true
      setTimeout(function(){
         _this.$refs.addSelectTree.initSelected('','')
      },0);
    },
    addClick (dataForm) {
      this.$refs[dataForm].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          this.dataForm.id=''
          params.append('id', this.dataForm.id)
          params.append('code', this.dataForm.code)
          //params.append('file', this.dataForm.file)
          if(this.dataForm.alias=='null'){
            this.dataForm.alias=''
          }
          params.append('alias', this.dataForm.alias)
          if(this.dataForm.image=='null'){
            this.dataForm.image=''
          }
          params.append('image', this.dataForm.image)
          //params.append('marketTime', this.dataForm.marketTime)
          this.dataForm.modelType='1'
          params.append('modelType', this.dataForm.modelType)
          if(this.dataForm.nameCh=='null'){
            this.dataForm.nameCh=''
          }
          params.append('nameCh', this.dataForm.nameCh)
          if(this.dataForm.nameEn=='null'){
            this.dataForm.nameEn=''
          }
          params.append('nameEn', this.dataForm.nameEn)
          params.append('sort', this.dataForm.sort)
          params.append('trainId', this.dataForm.trainId)
          params.append('year', this.dataForm.year)
          modelAdd(params).then(res => {
            if(res.data.code === 100){
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            }else{
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('请完善车型信息')
        }
      })
    },
    // 查看
    check (row) {
      this.dialogStatus = 'check'
      this.dialogFormVisible = true
      this.urlImg = sysServerUrl + 'sys/upload/display?filePath=' + row.image
    },
    // 详情
    handelDetile (row) {      
      this.resetTemp()
      this.dataForm = Object.assign({}, row)
      this.imgList = []
      if(row.image !== ''){
        var img = {url: sysServerUrl + 'sys/upload/display?filePath=' + row.image }
        this.imgList.push(img)
      }
      this.dialogStatus = 'detail'
      this.dialogFormVisible = true
    },
    // 编辑
    handeledit (row) {
      this.resetTemp()
      this.dataForm = Object.assign({}, row)
      this.imgList=[]
      if(row.image !== null && row.image !== '' && row.image !== 'null'){
        var img = {url: sysServerUrl + 'sys/upload/display?filePath=' + row.image }
        this.imgList.push(img)
      }
      this.dialogStatus = 'edit'
      this.dialogFormVisible = true
    },
    editClick (dataForm) {
      this.$refs[dataForm].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          params.append('id', this.dataForm.id)
          params.append('trainId', this.dataForm.trainId)
         
          params.append('year', this.dataForm.year)
          params.append('code', this.dataForm.code)

          if(this.dataForm.nameCh=='null'){
            this.dataForm.nameCh=''
          }
          params.append('nameCh', this.dataForm.nameCh)
          if(this.dataForm.nameEn=='null'){
            this.dataForm.nameEn=''
          }
          params.append('nameEn', this.dataForm.nameEn)

          if(this.dataForm.alias=='null'){
            this.dataForm.alias=''
          }
          params.append('alias', this.dataForm.alias)
          if(this.dataForm.image=='null'){
            this.dataForm.image=''
          }
          params.append('image', this.dataForm.image)

          params.append('sort', this.dataForm.sort)
          
          this.dataForm.modelType='1'
          params.append('modelType', this.dataForm.modelType)
          modelEdit(params).then(res => {
            if (res.data.code === 100) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('请完善车型信息')
        }
      })
    },
    // 删除
    handeldelete (row) {
      this.$confirm('确定删除当前车型 ' + row.nameCh + '?', '删除车型', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var params = '?id=' + row.id
        modelDel(params).then(res => {
          if (res.data.code === 100) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            
            if(this.resultList!=null&&this.resultList.length==1){
              this.currentPage =this.currentPage-1
            }
            this.dataList()
          }else{
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
      }).catch((error) => {
        this.$message.error('删除失败')
      })
    },
    // 车型配置
    config (row) {
      this.modelId = row.id
      this.dialogStatus = 'config'
      this.textMap.config=  '['+row.nameCh +'] '+this.textMap.config
      this.dialogFormVisible = true
      this.getCfgList()
    },
    getCfgList () {
      var params = '?id=' + this.modelId
      cfgData(params).then(res => {
        this.modelCfgList = res.data.data
      })
    },
    addConfig () {
      var sortVal = ''
      if (this.modelCfgList.length !== 0) {
        sortVal = Number(this.modelCfgList[this.modelCfgList.length - 1].sort) + Number(1)
        if(sortVal>9999){
          sortVal=9999
        }
      } else {
        sortVal = 1
      }

      var params = '?id=' + this.modelId
      cfgData(params).then(res => {
          this.modelCfgList = res.data.data
          this.modelCfgList.push({
          code: '',
          alias: '',
          sort: sortVal
        })
      })

     
    },
    delAllConfig () {
      this.$confirm('确认删除全部 配置代码？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var params = '?modelId=' + this.modelId
        delAllCfg(params).then(res => {
          if (res.data.code === 100) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getCfgList()
          }else{
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
      }).catch((error)=>{
        this.$message.error('删除失败')
      })
    },
    // 保存当前信息
    handelSaveConfig (row) {
      let curCode= row.code
      let curAlias =row.alias
      if(curCode==null||curCode.length==0){
        this.$message.error('请输入配置代码')
         return;
      }
      if(curAlias==null||curAlias.length==0){
        this.$message.error('请输入手册编码')
         return;
      }

      var params = new URLSearchParams()
      if (row.id === undefined) {
        row.id = ''
      }
      if (row.pid === undefined) {
        row.pid = this.modelId
      }
      params.append('id', row.id)
      params.append('pid', row.pid)
      params.append('code', row.code)
      params.append('modelType', '2')
      params.append('alias', row.alias)
      params.append('sort', row.sort)
      saveConfig(params).then(res => {
        if (res.data.code === 100) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.getCfgList()
        }else{
           this.$message.error(res.data.msg)
        }
      })
    },
    // 删除当前行
    handelDelConfig (row) {
      var _this=this
      this.$confirm('确定删除当前行配置代码?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var params = '?id=' + row.id
        delConfig(params).then(res => {
          if (res.data.code === 100) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getCfgList()
          }else{
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
      }).catch((error)=>{
        _this.$message.error('删除失败')
      })
    },
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$tefs[formInline].resetFields()
      }
      this.dataList()
    },
    // 时间转换
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    //this.uploadUrl = sysServerUrl + 'simgen-sysmgt/sys/upload/attach?flag=carModel'
    this.uploadUrl = sysServerUrl + 'simgen-sysmgt/sys/upload/accessory?type=procedure'
    this.dataList()
    this.getTrainList()
  }
}
</script>
<style>

  .el-dialog .el-table .el-input__inner{
    height: 28px;
    line-height: 28px;
    padding: 0 8px;
  }
  .el-dialog .el-table .el-input{
    width: 100% !important;
  }
  .tableDetail .el-dialog .el-table .el-table__cell {
    padding: 5px 0 !important
  }
  .el-upload-list__item {
   transition: none !important;
  }
  .imgShow{
    width: 150px;
  }
</style>
<style>
  .inline-block{
    display: inline-block
  }
  .margin-right-10{
    margin-right: 10px;
  }
  .margin-left-10{
    margin-left: 10px;
  }
</style>
